<template>
  <form
    v-if="action.name === actionTypesType.personalQuality && action.active"
    class="personal-quality">
    <div class="skill-category">
      <p class="form-title">
        {{ $t('views.add_new_address_job.address_job_step_3.personal_quality.title') }}
      </p>
      <p>
        {{ $t('views.add_new_address_job.address_job_step_3.personal_quality.subtitle') }}
      </p>
    </div>
    <div v-if="personalQualities" class="form-check-group">
      <div v-for="quality in personalQualities" :key="quality.id" class="form-check custom-check">
        <input
          :id="`quality-${quality.id}`"
          v-model="selectedPersonalQualities"
          class="form-check-input new-quality-radio"
          type="checkbox"
          :value="quality">
        <label class="form-check-label custom-label" :for="`quality-${quality.id}`">{{ quality.name }}</label>
      </div>
    </div>
    <small
      v-if="v.selectedPersonalQualities.$invalid"
      class="invalid"
    >{{ $t('form.validation.required') }}
    </small>
    <br>
    <small
      v-if="v.selectedPersonalQualities.maxLength.$invalid"
      class="invalid"
    >{{ $t('views.register_step_3.personal_quality.invalid_selections') }} {{ selectedPersonalQualities.length }}
    </small>
  </form>
</template>
<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required } from '@vuelidate/validators';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapMutations, mapState } from 'vuex';

import { ActionTypesAddAddressJob } from '@/js/enums/candidate-actions-type';

import ActionType from '../interfaces/action-type';
import { AddAddressJob } from '../interfaces/address-job';
import { Skill } from '../interfaces/skill';

export default defineComponent({
  name: 'CandidateProfilePersonalQuality',
  props: {
    personalQualities: {
      type: Object as PropType<Skill[]>,
      required: true,
    },
    action: {
      type: Object as PropType<ActionType>,
      required: true,
    },
  },
  setup() {
    const v = useVuelidate();
    return {
      v,
    };
  },
  data() {
    return {
      selectedPersonalQualities: [] as Skill[],
      localAddressJob: (cloneDeep(this.addressJob) as unknown) as AddAddressJob,
      actionTypesType: ActionTypesAddAddressJob,
    };
  },
  validations() {
    return {
      selectedPersonalQualities: { required, minLength: minLength(1), maxLength: maxLength(10) },
    };
  },
  computed: {
    ...mapState('job', [
      'addressJob',
    ]),
  },
  watch: {
    addressJob(newJob: AddAddressJob) {
      this.localAddressJob = newJob;
    },
    async selectedPersonalQualities(): Promise<void> {
      await this.localOnSelectPersonalQualities();
    },
  },
  async mounted(): Promise<void> {
    this.localAddressJob = cloneDeep(this.addressJob);
    this.setStoreAddressJobToLocal();
  },
  methods: {
    ...mapMutations('job', [
      'setAddressJob',
    ]),
    async localOnSelectPersonalQualities(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        personalQualities: this.selectedPersonalQualities,
      };
      await this.setAddressJob(this.localAddressJob);
    },
    setStoreAddressJobToLocal(): void {
      this.selectedPersonalQualities = this.localAddressJob.personalQualities;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.add-address-job .form-check-group {
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

  .form-check-label {
    font-size: 16px;
    min-height: 70px;
  }
}
</style>
