// eslint-disable-next-line import/prefer-default-export
export const searchMapboxAddress = async (addressQuery: string) => {
  const mapboxKey = window.MAPBOX_KEY;

  const apiUrl = 'https://api.mapbox.com/autofill/v1/suggest/';
  const url = `${apiUrl + addressQuery}?access_token=${mapboxKey}&proximity=ip&language=fr`;

  return window.ky.get(url).json();
};

// eslint-disable-next-line import/prefer-default-export
export const searchMapboxPlaces = async (townQuery: string) => {
  const mapboxKey = window.MAPBOX_KEY;

  const sessionToken = window.localStorage.getItem('sessionToken') ?? crypto.randomUUID();

  const apiUrl = 'https://api.mapbox.com/search/geocode/v6/forward';

  const parameters = new URLSearchParams({
    q: townQuery,
    access_token: mapboxKey as string,
    session_token: sessionToken,
    language: 'fr',
    country: 'fr',
    types: 'place',
  });
  const url = `${apiUrl}?${parameters.toString()}`;

  const { features } = await window.ky.get(url).json();
  return features;
};
