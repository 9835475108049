import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toast-notification';

import Contract from '../interfaces/contract';
import Remote from '../interfaces/remote';
import WorkingTime from '../interfaces/working-time';

// eslint-disable-next-line import/prefer-default-export
export function useJobOptions() {
  const contracts = ref<Contract[]>([]);
  const workingTimes = ref<WorkingTime[]>([]);
  const remotes = ref<Remote[]>([]);

  const toast = useToast();
  const { t } = useI18n();

  const fetchOptions = async (): Promise<void> => {
    try {
      const [contractsResponse, workingTimesResponse, remotesResponse] = await Promise.all([
        window.ky.get('/api/contracts/').json<Array<Contract>>(),
        window.ky.get('/api/working-times/').json<Array<WorkingTime>>(),
        window.ky.get('/api/remotes/').json<Array<Remote>>(),
      ]);

      contracts.value = contractsResponse;
      workingTimes.value = workingTimesResponse;
      remotes.value = remotesResponse;
    } catch {
      toast.error((t('components.new_job_form.error_loading_options') as string));
    }
  };

  return {
    contracts,
    workingTimes,
    remotes,
    fetchOptions,
  };
}
