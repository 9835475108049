<template>
  <div>
    <div class="qr-code-ad-wrapper">
      <div v-if="icon" class="qr-code-ad-icon">
        <v-sprite :icon="icon" class="sticker-icon" />
      </div>
      <div class="qr-code-ad" :class="{ 'qr-code-ad-condensed': condensed }">
        <div v-if="!isHeadHunter" class="qr-code-ad-number">
          <div class="qr-code-ad-number-content">{{ number }}</div>
          <div class="qr-code-ad-number-extra">{{ extra }}</div>
        </div>
        <h2 class="qr-code-ad-title">{{ title }}</h2>
        <div class="qr-code-ad-description">
          <slot name="description" />
          <ul v-if="list" class="qr-code-ad-description-list">
            <li
              v-for="(d, index) in list"
              :key="index"
              :class="{ 'qr-code-ad-description-list-item-no-check': d === '...' }"
              class="qr-code-ad-description-list-item">
              {{ d }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="qr-code-cta text-center">
      <slot name="cta" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'QrCodeAd',
  props: {
    number: {
      type: Number,
      required: true,
    },
    extra: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    list: {
      type: Object as PropType<String[]>,
      default: undefined,
      required: false,
    },
    condensed: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapGetters('recruiter', [
      'isPremium',
      'isHeadHunter',
    ]),
  },

});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.qr-code-ad-wrapper {
  display: flex;

  @include mq($from: tablet) {
    flex-direction: column;
  }

  .cdtn & {
    display: block;
  }
}

.qr-code-ad {
  color: $body-color;
  display: flex;
  flex-direction: column;
  height: 100%;

  .cdtn & {
    min-height: unset;
  }

  @include mq($from: tablet) {
    min-height: 334px;
  }

  &.qr-code-ad-condensed {
    height: auto;
    min-height: auto;
  }
}

.qr-code-ad-icon {
  align-items: center;
  aspect-ratio: 1;
  background-color: $light;
  border-radius: 100%;
  color: $pink;
  display: inline-flex;
  height: 58px;
  justify-content: center;
  width: 58px;

  .cdtn & {
    margin-bottom: 32px;
  }

  @include mq($from: tablet) {
    align-self: center;
  }

  .svg-shop-dims {
    height: 28px;
    width: 23px;
  }
}

.qr-code-ad-number {
  align-items: baseline;
  color: $pink;
  display: flex;
  font-size: 35px;
  font-weight: 700;
  gap: 10px;
  width: fit-content;
}

.qr-code-ad-number-content {
  position: relative;

  &::after {
    background-color: $pink;
    border-radius: 10px;
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
  }
}

.qr-code-ad-number-extra {
  color: $black;
  font-size: 14px;
  font-weight: 400;
}

.qr-code-ad-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.qr-code-ad-description {
  font-size: 14px;
  margin-top: 8px;

  .qr-code-ad-description-list {
    padding-left: 20px;
  }

  .qr-code-ad-description-list-item {
    list-style-image: url('../../images/svg/check-blue.svg');
    margin-bottom: 5px;

    &.qr-code-ad-description-list-item-no-check {
      list-style: none;
    }
  }

  .qr-code-ad-description-list-item-last {
    list-style-type: none;
  }
}

.qr-code-cta {
  margin-top: auto;
  padding: 0 50px;

  @include mq($from: desktop) {
    padding: 0 5px;
  }

  @include mq($from: extra-wide) {
    padding: 0 5px;
  }

  .btn:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
