import { ActionContext } from 'vuex';

import { Advantage, AdvantageCategory } from '@/js/interfaces/advantage';
import { RecruitmentStep } from '@/js/interfaces/recruitment-step';
import State from '@/js/store/state';

export const ADVANTAGE = 0;
export const RECRUITMENT = 1;
export const MEDIA = 2;
export const VALUES = 3;

export enum BrandTab {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Advantage = ADVANTAGE,
  Recruitment = RECRUITMENT,
  Media = MEDIA,
  Values = VALUES,
}

interface BrandState {
  // The current tab in the brand page
  currentTab: BrandTab;

  // True if the content of one of the tab has been updated
  isUpdated: boolean;

  // Advantages
  advantages: Array<AdvantageCategory>;
  selectedAdvantages: Array<number>;

  // Recruitment steps
  recruitmentSteps: Array<RecruitmentStep>;
  selectedRecruitmentSteps: Array<number>;

  // Media
  youtubeLink: string;
  picture: string | null;
  pictureTwo: string | null;
  pictureThree: string | null;

  // Values
  values: string;
}

const defaultState: BrandState = {
  currentTab: BrandTab.Advantage,
  isUpdated: false,
  advantages: [],
  selectedAdvantages: [],
  recruitmentSteps: [],
  selectedRecruitmentSteps: [],
  youtubeLink: '',
  picture: null,
  pictureTwo: null,
  pictureThree: null,
  values: '',
};

type Context = ActionContext<BrandState, State>;

const mutations = {
  setCurrentTab(state: BrandState, tab: BrandTab): void {
    state.currentTab = tab;
  },
  setIsUpdated(state: BrandState, isUpdated: boolean): void {
    state.isUpdated = isUpdated;
  },
  setAdvantages(state: BrandState, advantages: Array<AdvantageCategory>): void {
    state.advantages = advantages;
  },
  setSelectedAdvantages(state: BrandState, selectedAdvantages: Array<number>): void {
    state.selectedAdvantages = selectedAdvantages;
  },
  setRecruitmentSteps(state: BrandState, recruitmentSteps: Array<RecruitmentStep>): void {
    state.recruitmentSteps = recruitmentSteps;
  },
  setSelectedRecruitmentSteps(state: BrandState, selectedRecruitmentSteps: Array<number>): void {
    state.selectedRecruitmentSteps = selectedRecruitmentSteps;
  },
  setYoutubeLink(state: BrandState, youtubeLink: string): void {
    state.youtubeLink = youtubeLink;
  },
  setPicture(state: BrandState, picture: string): void {
    state.picture = picture;
  },
  setPictureTwo(state: BrandState, pictureTwo: string): void {
    state.pictureTwo = pictureTwo;
  },
  setPictureThree(state: BrandState, pictureThree: string): void {
    state.pictureThree = pictureThree;
  },
  setValues(state: BrandState, values: string): void {
    state.values = values;
  },
};

const actions = {
  /**
   * Set the current tab in the navigation
   * @param context
   * @param tab
   */
  setCurrentTab(context: Context, tab: BrandTab): void {
    context.commit('setCurrentTab', tab);
  },

  /**
   * Define if an interface has been updated
   * @param context
   * @param isUpdated
   */
  setIsUpdated(context: Context, isUpdated: boolean): void {
    context.commit('setIsUpdated', isUpdated);
  },

  /**
   * Fetch the advantages from the database
   * @param context
   */
  async getAdvantages(context: Context): Promise<void> {
    const data = await window.ky.get('/api/advantages/').json();
    context.commit('setAdvantages', data);
  },

  /**
   * Fetch the recruitment steps from the database
   * @param context
   */
  async getRecruitmentSteps(context: Context): Promise<void> {
    const data = await window.ky.get('/api/recruitment-steps/').json();
    context.commit('setRecruitmentSteps', data);
  },

  /**
   * Toggle an advantage as selected or not
   * @param context
   * @param advantage
   */
  toggleSelectedAdvantage(context: Context, advantage: Advantage): void {
    context.commit('setIsUpdated', true);
    const selectedAdvantages = [...context.state.selectedAdvantages];
    const index = selectedAdvantages.indexOf(advantage.id);

    if (index === -1) {
      selectedAdvantages.push(advantage.id);
    } else {
      selectedAdvantages.splice(index, 1);
    }

    context.commit('setSelectedAdvantages', selectedAdvantages);
  },

  /**
   * Set the selected advantages
   * @param context
   * @param selectedAdvantages
   */
  setSelectedAdvantages(context: Context, selectedAdvantages: Array<number>): void {
    context.commit('setSelectedAdvantages', selectedAdvantages);
  },

  /**
   * Save the advantages of the current address into the database via the API
   * @param context
   */
  async saveAdvantages(context: Context): Promise<void> {
    await window.ky.patch(`/api/addresses/${context.rootState.recruiter.currentAddress.uuid}/`, {
      json: {
        advantages: context.state.selectedAdvantages,
      },
    }).json();
    context.commit('setIsUpdated', false);
  },

  /**
   * Set the selected recruitment steps
   *
   * @param context
   * @param selectedRecruitmentSteps
   */
  setSelectedRecruitmentSteps(context: Context, selectedRecruitmentSteps: Array<number>): void {
    context.commit('setSelectedRecruitmentSteps', selectedRecruitmentSteps);
  },

  /**
   * Save the recruitment steps of the current address into the database via the API
   * @param context
   */
  async saveRecruitmentSteps(context: Context): Promise<void> {
    await window.ky.patch(`/api/addresses/${context.rootState.recruiter.currentAddress.uuid}/`, {
      json: {
        recruitmentSteps: context.state.selectedRecruitmentSteps,
      },
    }).json();
    context.commit('setIsUpdated', false);
  },

  /**
   * Set the YouTube link
   *
   * @param context
   * @param youtubeLink
   */
  setYoutubeLink(context: Context, youtubeLink: string): void {
    context.commit('setYoutubeLink', youtubeLink);
  },

  /**
   * Set the picture 1
   *
   * @param context
   * @param picture
   */
  setPicture(context: Context, picture: string | null): void {
    context.commit('setPicture', picture);
  },

  /**
   * Set the picture 2
   *
   * @param context
   * @param pictureTwo
   */
  setPictureTwo(context: Context, pictureTwo: string | null): void {
    context.commit('setPictureTwo', pictureTwo);
  },

  /**
   * Set the picture 3
   *
   * @param context
   * @param pictureThree
   */
  setPictureThree(context: Context, pictureThree: string | null): void {
    context.commit('setPictureThree', pictureThree);
  },

  /**
   * Save the medias of the current address into the database via the API
   * @param context
   */
  async saveMedias(context: Context): Promise<void> {
    const payload: {[k: string]: string | null;} = {
      youtubeLink: context.state.youtubeLink,
    };
    const regex = /^(https?)/;
    if ((context.state.picture && !regex.test(context.state.picture)) || !context.state.picture) {
      payload.picture = context.state.picture;
    }
    if ((context.state.pictureTwo && !regex.test(context.state.pictureTwo)) || !context.state.pictureTwo) {
      payload.pictureTwo = context.state.pictureTwo;
    }
    if ((context.state.pictureThree && !regex.test(context.state.pictureThree)) || !context.state.pictureThree) {
      payload.pictureThree = context.state.pictureThree;
    }

    await window.ky.patch(`/api/addresses/${context.rootState.recruiter.currentAddress.uuid}/`, { json: payload }).json();
    context.commit('setIsUpdated', false);
  },

  /**
   * Set the values
   *
   * @param context
   * @param values
   */
  setValues(context: Context, values: string): void {
    context.commit('setValues', values);
  },

  /**
   * Save the values of the current address into the database via the API
   * @param context
   */
  async saveValues(context: Context): Promise<void> {
    await window.ky.patch(`/api/addresses/${context.rootState.recruiter.currentAddress.uuid}/`, {
      json: {
        values: context.state.values,
      },
    }).json();
    context.commit('setIsUpdated', false);
  },
};

const getters = {};

const brand = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default brand;
