<template>
  <div class="address card">
    <div class="card-body">
      <div class="address-title text-uppercase">
        <div v-if="address.logo" class="address-avatar">
          <img :src="address.logo" alt="" class="img-fluid" width="35" height="35">
        </div>
        {{ address.name }}
      </div>

      <div class="form-check form-switch custom-switch">
        <input
          :id="`active${localAddress.id}`"
          v-model="localAddress.active"
          class="form-check-input btn-checked-red-to-green"
          type="checkbox"
          role="switch"
          :aria-checked="localAddress.active"
          @change="localToggleActiveAddress()">
        <label class="form-check-label" :for="`active${localAddress.id}`">
          {{ $t('components.address_card.active') }}
        </label>
      </div>
      <div class="address-content steel-text">{{ address.content.placeName }}</div>

      <div v-if="!address.subscription && !address.premium" class="address-card-status">
        <div class="mb-3"><strong>{{ $t('components.store_switch.no_subscription') }}</strong></div>

        <div class="mb-3">
          <button type="button" class="btn btn-sm btn-outline-primary">
            {{ $t('actions.pay') }}
          </button>
        </div>
      </div>

      <div v-if="address.subscription && address.subscription.status !== 'active'" class="address-card-status">
        <strong>{{ $t('components.store_switch.subscription_is_over') }}</strong>
      </div>

      <div class="d-flex justify-content-between gap-3 address-card-actions text-s">
        <div>
          <a href="" @click.prevent="manageModalOpened = true" @keyup.enter.prevent="manageModalOpened = true">
            <v-sprite class="action-icons-space" icon="gear" />
            <span class="sr-only">{{ $t('components.address_card.manage') }}</span>
          </a>
          <a href="" @click.prevent="manageModalOpened = true" @keyup.enter.prevent="manageModalOpened = true">
            <span class="address-card-action">{{ $t('actions.update') }}</span>
          </a>
        </div>

        <div>
          <a v-if="!address.isParent" href="" @click.prevent="deleteModalOpened = true" @keyup.enter.prevent="deleteModalOpened = true">
            <v-sprite class="action-icons-space" icon="trash" />
            <span class="sr-only">{{ $t('components.address_card.delete') }}</span>
          </a>
          <a v-if="!address.isParent" href="" @click.prevent="deleteModalOpened = true" @keyup.enter.prevent="deleteModalOpened = true">
            <span class="address-card-action">{{ $t('actions.delete') }}</span>
          </a>
        </div>
      </div>
    </div>

    <v-modal :id="manageModalId" v-model="manageModalOpened" size="modal-lg">
      <div class="text-center d-flex">
        <div class="form-check form-switch custom-switch">
          <input
            :id="`active${localAddress.id}`"
            v-model="localAddress.active"
            class="form-check-input btn-checked-red-to-green"
            type="checkbox"
            role="switch"
            :aria-checked="localAddress.active"
            @change="localToggleActiveAddress()">
          <label class="form-check-label" :for="`active${localAddress.id}`" />
        </div>
        <h4 class="mb-2 text-uppercase">{{ address.name }}</h4>
      </div>

      <v-address-form
        v-model="localAddress"
        @on-submit="saveAddress()"
        @on-cancel="manageModalOpened = false" />

      <div v-if="address.subscription && address.subscription.status === 'active'" class="text-right">
        <span>{{ $t('components.manage_address.label_stop_subscription') }}</span>
        <a href="/#contact" target="_blank" class="fw-bold">{{ $t('components.manage_address.link_stop_subscription') }}</a>
      </div>
    </v-modal>

    <v-modal :id="deleteModalId" v-model="deleteModalOpened">
      <div class="px-3 py-3">
        <h5 class="text-center">{{ $t('components.address_card.delete_modal.title', { name: address.name }) }}</h5>
        <p class="text-center">{{ $t('components.address_card.delete_modal.content', { name: address.name }) }}</p>

        <div class="d-flex justify-content-center gap-3">
          <button type="button" class="btn btn-outline-primary btn-rounded" @click.prevent="$emit('on-cancel')">
            {{ $t('actions.cancel') }}
          </button>
          <button type="button" class="btn btn-primary btn-rounded" @click.prevent="deleteAddress">
            {{ $t('actions.delete') }}
          </button>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapGetters, mapState } from 'vuex';

import Price from '@/js/interfaces/price';

import Address from '../interfaces/address';
import { ToggleActiveAddressPayload } from '../interfaces/payload';
import Product from '../interfaces/product';

export default defineComponent({
  name: 'AddressCard',
  props: {
    address: {
      type: Object as PropType<Address>,
      required: true,
    },
  },
  emits: ['on-cancel'],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      localAddress: cloneDeep(this.address) as Address,
      manageModalOpened: false,
      deleteModalOpened: false,
      subscription: {} as Product,
      price: {} as Price,
    };
  },
  computed: {
    manageModalId(): string {
      return `manageAddressModal-${this.address.uuid}`;
    },
    deleteModalId(): string {
      return `deleteAddressModal-${this.address.uuid}`;
    },
    ...mapState('recruiter', [
      'user',
    ]),
    ...mapGetters('register', [
      'goods',
      'subscriptions',
    ]),
  },
  mounted() {
    const subscriptionId = this.localAddress.subscription ? this.localAddress.subscription.plan.product : '';
    const priceId = this.localAddress.subscription ? this.localAddress.subscription.plan.id : '';
    this.subscription = subscriptionId
      ? this.subscriptions.find((subscription: Product) => subscription.id === subscriptionId) : this.subscriptions[0];
    const subscriptionPrice = this.subscription.prices.find((price) => price.id === priceId);
    this.price = priceId && subscriptionPrice ? subscriptionPrice : this.subscription.prices[1];
  },
  methods: {
    ...mapActions('recruiter', [
      'getProfile',
      'toggleActiveAddress',
    ]),
    async localToggleActiveAddress(): Promise<void> {
      const payload: ToggleActiveAddressPayload = { uuid: this.localAddress.uuid, active: this.localAddress.active };
      await this.toggleActiveAddress(payload);
    },
    async saveAddress(): Promise<void> {
      try {
        const payload = {
          name: this.localAddress.name,
          content: this.localAddress.content,
          receiveApplicationByEmail: this.localAddress.receiveApplicationByEmail,
          email: this.localAddress.email,
          frequency: this.localAddress.frequency,
          activitySector: this.localAddress.activitySector.id,
          logo: '',
        };

        if (this.localAddress.logo && this.localAddress.logo.startsWith('data:image/')) {
          payload.logo = this.localAddress.logo;
        }

        // Patch the address's jobs
        await window.ky.patch(`/api/addresses/${this.address.uuid}/`, { json: payload }).json<Address>();

        this.toast.success((this.$t('components.address_card.update.success') as string));

        await this.getProfile();

        this.manageModalOpened = false;
      } catch (error) {
        if (error.name === 'HTTPError') {
          const data = error.response.json();
          if ('logo' in data) {
            this.toast.error(data.logo[0]);
          }
        } else {
          this.toast.error((this.$t('components.address_card.update.error') as string));
        }
      }
    },
    async deleteAddress(): Promise<void> {
      try {
        await window.ky.delete(`/api/addresses/${this.address.uuid}/`).json<void>();
        this.deleteModalOpened = false;

        // Wait for the modal to be closed before refreshing the content
        setTimeout(async () => {
          await this.getProfile();
          this.toast.success((this.$t('components.address_card.delete_modal.success') as string));
        }, 300);
      } catch {
        this.toast.error((this.$t('components.address_card.delete_modal.error') as string));
      }
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.address {
  display: flex;
  font-family: $headings-font-family;

  .card-body {
    display: flex;
    flex-direction: column;
    line-height: $headings-line-height;
    padding: $spacer * 1.5;
  }
}

.address-title {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  gap: 5px;
  margin-bottom: 5px;
  word-break: break-all;
}

.address-avatar {
  align-items: center;
  border-radius: 35px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .15);
  display: flex;
  height: 35px;
  justify-content: center;
  overflow: hidden;
  width: 35px;
}

.form-check {
  margin-bottom: 10px;
}

.address-content {
  font-size: 14px;
  margin-bottom: 10px;
}

.address-card-status {
  font-size: 14px;
}

.action-icons-space {
  margin-right: 5px;
}

.address-card-actions {
  color: $primary;
  font-weight: 400;
  margin-top: auto;
  width: 100%;
}

.address-card-action {
  text-decoration: underline;
}
</style>
