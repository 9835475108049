<template>
  <div class="header-address-job">
    <div v-if="currentStep === 1" class="step-back">
      <router-link
        class="btn-icon"
        :to="{ name: routeNames.jobs }">
        <v-sprite color="" icon="back-small" />
        {{ $t('actions.back') }}
      </router-link>
    </div>
    <div class="content">
      <p class="title">Ajout d'un métier</p>
      <v-add-address-job-bread-crumb />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import routesNames, { RoutesNames } from '../router/route-names';

export default defineComponent({
  name: 'HeaderAddAddressJob',
  data() {
    return {};
  },
  computed: {
    routeNames(): RoutesNames {
      return routesNames;
    },
    ...mapState('job', [
      'currentStep',
    ]),
  },
  mounted() {},
  methods: {},
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.step-back {
  @include mq($from: tablet) {
    font-size: 20px;
    font-weight: 700;
  }

  a {
    text-decoration: none;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  margin-block: 20px;

  @include mq($from: tablet) {
    margin-block: 32px;
  }

  .title {
    display: none;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0;

    @include mq($from: tablet) {
      display: block;
    }

    @include mq($from: desktop) {
      font-size: 32px;
    }
  }

  .breadcrumb {
    margin-bottom: 0;

    @include mq($from: tablet) {
      width: 300px;
    }
  }
}
</style>
