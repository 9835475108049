<template>
  <div class="store-switch-address-content">
    <div class="store-switch-address">
      <!--we disable keyboard events because this is only shown on mobile anyway-->
      <!--eslint-disable-next-line vuejs-accessibility/click-events-have-key-events-->
      <div class="mobile-click" @click.prevent="clickOnSwitchAddress();" />
      <div class="store-switch-address-avatar">
        <div v-if="address.logo">
          <img :src="address.logo" alt="" class="img-fluid">
        </div>
        <div
          v-else
          class="bulletpoint"
          :class="[currentAddress && (address.uuid !== currentAddress.uuid) ? 'bg-primary' : 'bg-secondary']" />
      </div>
      <div>
        <div class="store-switch-address-title">{{ address.name }}</div>
        <div class="store-switch-address-subtitle">{{ address.isParent ? $t('views.addresses.parent') : address.content.placeName }}</div>
        <div v-if="!address.subscription && !address.premium" class="store-switch-address-subtitle">
          <strong>{{ $t('components.store_switch.no_subscription') }}</strong>
        </div>
        <div
          v-if="address.subscription && address.subscription.status !== 'active' && !address.premium"
          class="store-switch-address-subtitle">
          <strong>{{ $t('components.store_switch.subscription_is_over') }}</strong>
        </div>
      </div>
    </div>
    <div>
      <button
        v-if="currentAddress && (address.uuid !== currentAddress.uuid)"
        :id="`switch-btn-${address.id}`"
        type="button"
        class="btn-sm btn btn-outline-primary btn-rounded mobile-hidden"
        @click.prevent="$emit('on-choose-address', address)">
        {{ $t('actions.access') }}
      </button>
      <button v-if="!address.subscription && !address.premium" type="button" class="btn btn-sm btn-primary btn-rounded">
        {{ $t('actions.pay') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';

import Address from '../interfaces/address';

export default defineComponent({
  name: 'StoreSwitchAddress',
  props: {
    address: {
      type: Object as PropType<Address>,
      required: true,
    },
  },
  emits: ['on-choose-address'],
  computed: {
    ...mapState('recruiter', [
      'currentAddress',
    ]),
  },
  methods: {
    clickOnSwitchAddress(): void {
      const button = document.querySelector(`#switch-btn-${this.address.id}`) as HTMLElement|undefined;
      if (button) {
        button.click();
      }
    },
  },
});

</script>

<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.store-switch-address-content {
  align-items: center;
  color: $body-color;
  display: flex;
  justify-content: space-between;
  padding: $spacer;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-400;
  }
}

.store-switch-address {
  align-items: center;
  display: flex;
  gap: $spacer;
  position: relative;
}

.store-switch-address-indicator {
  width: 10px;
}

.store-switch-address-avatar {
  @include store-avatar(50px);
  display: inline;
}

.store-switch-address-title {
  font-family: $headings-font-family;
  font-size: 16px;
  font-weight: 900;
}

.store-switch-address-subtitle {
  color: $gray-500;
  font-size: 14px;
}

.bulletpoint {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.mobile-hidden {
  display: none;

  @include mq($from: tablet) {
    display: block;
  }
}

.mobile-click {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;

  @include mq($from: tablet) {
    display: none;
  }
}
</style>
