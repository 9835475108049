<template>
  <div>
    <div v-if="logoUrl" class="logo">
      <router-link v-if="loggedIn" :to="{ name: routeNames.dashboard }">
        <img :src="logoUrl" alt="decide.work">
      </router-link>
      <a v-else href="/">
        <img :src="logoUrl" alt="decide.work">
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';

import LogoType from '../enums/logoType.d';
import routesNames from '../router/route-names';

export default defineComponent({
  name: 'AppLogo',
  props: {
    color: {
      type: String as PropType<LogoType>,
      default: LogoType.none,
      required: false,
    },
  },
  data() {
    return {
      routeNames: routesNames,
    };
  },
  computed: {
    ...mapState('recruiter', [
      'loggedIn',
    ]),
    logoUrl(): string {
      switch (this.color) {
        case LogoType.blue: {
          return new URL('@/images/decide-logo-blue.svg', import.meta.url).href;
        }
        case LogoType.pink: {
          return new URL('@/images/decide-logo-pink-2.svg', import.meta.url).href;
        }
        default: {
          return new URL('@/images/decide-logo.svg', import.meta.url).href;
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';

.logo {
  margin: $spacer 0;
  padding-left: 0;
}
</style>
