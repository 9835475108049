// eslint-disable-next-line import/prefer-default-export
export enum ActionTypesType {
  experience = 'experience',
  personalQuality = 'personal_quality',
  professionalKnowledge = 'professional_knowledge',
}

export enum ActionTypesAddAddressJob {
  licences = 'licences',
  language = 'language',
  experience = 'experience',
  personalQuality = 'personal_quality',
  professionalKnowledge = 'professional_knowledge',
}
