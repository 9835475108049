<template>
  <div v-if="localHeadHunterRegisterPayload" class="register-information-detail">
    <div class="form-group form-group-required">
      <label for="email" class="form-label">{{ $t('components.register_information.email.label') }}</label>
      <input
        id="email"
        v-model="localHeadHunterRegisterPayload.email"
        type="email"
        class="form-control"
        name="email"
        :placeholder="$t('components.register_information.email.placeholder')"
        :class="{ 'is-invalid': localHeadHunterRegisterPayload.email && v.localHeadHunterRegisterPayload.email.$invalid }"
        @change="localSetHeadHunter()">
      <div v-if="localHeadHunterRegisterPayload.email && !v.localHeadHunterRegisterPayload.email.required" class="invalid-feedback">
        {{ $t('form.validation.required') }}
      </div>
      <div v-if="!v.localHeadHunterRegisterPayload.email.email" class="invalid-feedback">{{ $t('form.validation.email') }}</div>
    </div>

    <div class="form-group form-group-required">
      <label for="password" class="form-label">{{ $t('components.register_information.password.label') }}</label>
      <v-password-field
        :id="'password'"
        v-model.trim="localHeadHunterRegisterPayload.password"
        :name="'password'"
        :input-class="{ 'is-invalid': localHeadHunterRegisterPayload.password && v.localHeadHunterRegisterPayload.password.$invalid }"
        @input="localSetHeadHunter()" />
      <div
        v-if="localHeadHunterRegisterPayload.password && !v.localHeadHunterRegisterPayload.password.required"
        class="invalid-feedback">
        {{ $t('form.validation.required') }}
      </div>
      <div
        v-if="localHeadHunterRegisterPayload.password && !v.localHeadHunterRegisterPayload.password.minLength"
        class="invalid-feedback">
        {{ $t('form.validation.min_length') }}
      </div>
      <small class="form-text text-xs text-muted">{{ $t('form.helper.password') }}</small>
    </div>

    <div class="form-group form-group-required">
      <label for="password_repeat" class="form-label">{{ $t('components.register_information.password_repeat.label') }}</label>
      <v-password-field
        :id="'password_repeat'"
        v-model.trim="localHeadHunterRegisterPayload.passwordRepeat"
        :name="'password_repeat'"
        :input-class="{ 'is-invalid': localHeadHunterRegisterPayload.passwordRepeat && v.localHeadHunterRegisterPayload.passwordRepeat.$invalid }"
        @input="localSetHeadHunter()" />
      <div
        v-if="localHeadHunterRegisterPayload.passwordRepeat && !v.localHeadHunterRegisterPayload.passwordRepeat.required"
        class="invalid-feedback">
        {{ $t('form.validation.required') }}
      </div>
      <div
        v-if="localHeadHunterRegisterPayload.passwordRepeat && !v.localHeadHunterRegisterPayload.passwordRepeat.sameAsPassword"
        class="invalid-feedback">
        {{ $t('form.validation.same_as_password') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import {
  email, minLength, required, sameAs,
} from '@vuelidate/validators';
import { cloneDeep } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import { HeadHunterRegisterPayload } from '../interfaces/head-hunter-register-payload';
import { hasFullName, isTelephone } from '../services/utils';

export default defineComponent({
  name: 'RegisterInformation',
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      localHeadHunterRegisterPayload: null as (HeadHunterRegisterPayload|null),
    };
  },
  computed: {
    ...mapState('register', [
      'addresses',
      'headHunter',
    ]),
  },
  watch: {
    async v(value): Promise<void> {
      await this.setInformationValid(!value.$invalid);
    },
  },
  mounted(): void {
    this.localHeadHunterRegisterPayload = cloneDeep(this.headHunter);
  },
  validations() {
    return {
      localHeadHunterRegisterPayload: {
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(8),
        },
        passwordRepeat: {
          required,
          sameAsPassword: sameAs(this.localHeadHunterRegisterPayload?.password),
        },
      },
    };
  },
  methods: {
    ...mapActions('register', [
      'setInformationValid',
      'setHeadHunter',
    ]),
    async localSetHeadHunter(): Promise<void> {
      await this.setHeadHunter(cloneDeep(this.localHeadHunterRegisterPayload));
    },
  },
});

</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.register-information-detail {
  margin-top: 32px;

  @include mq($from: tablet) {
    margin-top: 62px;
  }
}

.form-group {
  .text-muted {
    display: block;
    text-align: right;
  }
}
</style>
