export const getUserNameFirstLetters = (firstName: string, lastName: string): string => ((firstName ? firstName[0] : '')
  + (lastName ? lastName[0] : '')).toUpperCase();

export const isTelephone = (value: string) => (/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(value));

export const hasFullName = (value: string) => value.split(' ').filter(Boolean).length > 1;

export const isEmpty = (object: object): boolean => object
  && Object.keys(object).length === 0
  && Object.getPrototypeOf(object) === Object.prototype;

export const getEnumKeyByValue = (enumObject: any, value: string): string | undefined => Object.keys(enumObject).find((key) => enumObject[key] === value);
