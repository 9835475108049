<template>
  <form
    v-if="action.name === actionTypesType.language && action.active"
    class="languages">
    <div class="skill-category">
      <p class="form-title">
        {{ $t('views.add_new_address_job.address_job_step_3.language.title') }}
      </p>
      <p>
        {{ $t('views.add_new_address_job.address_job_step_3.language.subtitle') }}
      </p>
    </div>
    <div class="form-check-group">
      <div v-for="language in languages" :key="language.id" class="form-check custom-check">
        <input
          :id="`language-${language.id}`"
          v-model="selectedLanguages"
          class="form-check-input new-language-radio"
          type="checkbox"
          :value="language">
        <label class="form-check-label custom-label" :for="`language-${language.id}`">
          <img :src="language.icon" alt="language-icon">
          <span class="language-name">{{ language.name }}</span>
        </label>
      </div>
    </div>
    <small
      v-if="v.selectedLanguages.$invalid"
      class="invalid"
    >{{ $t('form.validation.required') }}
    </small>
  </form>
</template>
<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapMutations, mapState } from 'vuex';

import { ActionTypesAddAddressJob } from '@/js/enums/candidate-actions-type';

import ActionType from '../interfaces/action-type';
import { AddAddressJob } from '../interfaces/address-job';
import Language from '../interfaces/language';

export default defineComponent({
  name: 'CandidateProfileLanguages',
  props: {
    languages: {
      type: Object as PropType<Language[]>,
      required: true,
    },
    action: {
      type: Object as PropType<ActionType>,
      required: true,
    },
  },
  setup() {
    const v = useVuelidate();
    return {
      v,
    };
  },
  data() {
    return {
      selectedLanguages: [] as Language[],
      localAddressJob: (cloneDeep(this.addressJob) as unknown) as AddAddressJob,
      actionTypesType: ActionTypesAddAddressJob,
    };
  },
  validations() {
    return {
      selectedLanguages: { required, minLength: minLength(1) },
    };
  },
  computed: {
    ...mapState('job', [
      'addressJob',
    ]),
  },
  watch: {
    addressJob(newJob: AddAddressJob) {
      this.localAddressJob = newJob;
    },
    async selectedLanguages(): Promise<void> {
      await this.localOnSelectLanguages();
    },
  },
  async mounted(): Promise<void> {
    this.localAddressJob = cloneDeep(this.addressJob);
    this.setStoreAddressJobToLocal();
  },
  methods: {
    ...mapMutations('job', [
      'setAddressJob',
    ]),
    async localOnSelectLanguages(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        languages: this.selectedLanguages,
      };
      await this.setAddressJob(this.localAddressJob);
    },
    setStoreAddressJobToLocal(): void {
      this.selectedLanguages = this.localAddressJob.languages;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.add-address-job .form-check-group {
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

  .form-check-label {
    flex-direction: column;
    gap: 16px;
    min-height: 140px;
  }
}
</style>
