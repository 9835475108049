import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toast-notification';

import { HTMLInputEvent } from '../interfaces/events';

// eslint-disable-next-line import/prefer-default-export
export function useUploadImage() {
  const selectedFile = ref('');
  const logo = ref('');
  const toast = useToast();
  const { t } = useI18n();

  const createImage = async (file: Blob): Promise<void> => {
    const reader = new FileReader();
    const fileDataUrl = await new Promise<string>((resolve) => {
      reader.addEventListener('load', () => resolve(reader.result as string));
      reader.readAsDataURL(file);
    });
    logo.value = fileDataUrl;
  };

  const onFileChange = async (e: InputEvent | HTMLInputEvent | DragEvent): Promise<void> => {
    const files = (e as HTMLInputEvent).target.files || (e as DragEvent).dataTransfer?.files;
    if (!files?.length) return;
    const file = files[0];
    const allowedExtensions = ['image/png', 'image/jpeg', 'image/svg+xml'];

    if (!allowedExtensions.includes(file.type)) {
      toast.error((t('composition.upload_image.format_error') as string));
      return;
    }

    selectedFile.value = files[0].name;
    await createImage(files[0]);
  };

  return {
    selectedFile,
    logo,
    onFileChange,
    createImage,
  };
}
