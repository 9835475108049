<template>
  <form class="edit-job-form" @submit.prevent="saveJob">
    <h5 class="text-center mb-3">{{ modelValue.name }}</h5>

    <div class="form-group">
      <label for="job" class="form-label">{{ $t('components.edit_job_form.job_label') }}</label>

      <div class="form-group">
        <input
          id="job"
          v-model="query"
          data-cy="new-job-form-input"
          type="text"
          class="form-control"
          autocomplete="off"
          @input="lookupJobHandler">
        <div v-if="suggestedJobs && suggestedJobs.length > 0" class="dropdown-menu show" data-cy="suggested-job-list">
          <button
            v-for="job in suggestedJobs"
            :key="job.id"
            type="button" class="dropdown-item"
            @click.prevent="onSelectJob(job)">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="job.Formatted.name" />
          </button>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label" for="company">{{ $t('components.edit_job_form.monthly_salary_label') }}</label>
        <input
          id="company"
          type="text"
          class="form-control"
          pattern="[\d\s]*"
          :title="$t('components.edit_job_form.incorrect_salary_format')"
          name="company"
          :value="formattedSalary"
          @input.prevent="setSalary($event as InputEvent)">
      </div>

      <div class="form-check form-switch job-card-switch">
        <input
          :id="`edit-job-form-active${localJob.id}`"
          v-model="localJob.active"
          class="form-check-input btn-checked-red-to-green"
          type="checkbox"
          role="switch"
          :aria-checked="localJob.active">
        <label class="form-check-label" for="active">
          {{ $t('components.job_card.active') }}
        </label>
      </div>

      <div class="form-check form-switch job-card-switch mb-3">
        <input
          :id="`edit-job-form-urgent${localJob.id}`"
          v-model="localJob.urgent"
          class="form-check-input btn-checked-red-to-green"
          type="checkbox"
          role="switch"
          :aria-checked="localJob.urgent">
        <label class="form-check-label" for="urgent">
          {{ $t('components.job_card.urgent') }}
        </label>
      </div>

      <div class="d-flex justify-content-center gap-3">
        <button type="button" class="btn btn-outline-primary btn-rounded" @click.prevent="$emit('cancel')">
          {{ $t('actions.cancel') }}
        </button>
        <button class="btn btn-primary btn-rounded" type="submit" :disabled="selectedJob === null">
          {{ $t('actions.save') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { cloneDeep, DebouncedFunc, throttle } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import AddressJob from '../interfaces/address-job';
import Job from '../interfaces/job';
import JobSuggestion from '../interfaces/job-suggestion';

export default defineComponent({
  name: 'EditJobForm',
  props: {
    modelValue: {
      type: Object as PropType<AddressJob>,
      required: true,
    },
  },
  emits: ['cancel', 'job-updated', 'update:modelValue'],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      query: this.modelValue.name,
      suggestedJobs: [] as JobSuggestion[],
      selectedJob: this.modelValue.job as (Job|null),
      localJob: cloneDeep(this.modelValue) as AddressJob,
      lookupJobHandler: throttle(this.lookupJob, 200) as DebouncedFunc<()=> void>,
    };
  },
  computed: {
    ...mapState('recruiter', [
      'currentAddress',
    ]),
    monthlySalary(): number {
      let s = this.localJob.monthlySalary ? this.localJob.monthlySalary.toString() : '';
      s = s.replaceAll(/\s+/g, '');
      return Number.parseInt(s ?? '0', 10);
    },
    formattedSalary() {
      let returnValue = '';

      if (this.monthlySalary) {
        returnValue = new Intl.NumberFormat('fr-FR').format(
          this.monthlySalary,
        );
      }

      return returnValue;
    },
  },
  methods: {
    ...mapActions('recruiter', [
      'updateJob',
    ]),
    // Search a job by name
    async lookupJob(): Promise<void> {
      if (this.query.length < 3) {
        if (this.query.length === 0) this.reset();
        return;
      }

      const data = await window.ky.get(`/api/jobs/search/?term=${this.query}`).json();
      this.suggestedJobs = data.hits;
    },

    // When the recruiter has selected a new job
    onSelectJob(value: Job): void {
      this.selectedJob = value;
      this.query = this.selectedJob.name;
      this.suggestedJobs = [];
    },

    // When the recruiter has clicked the "add job" button
    async saveJob(): Promise<void> {
      if (this.selectedJob === null) {
        return;
      }

      try {
        // Add the job
        await this.updateJob({
          uuid: this.modelValue.uuid,
          data: {
            job: this.selectedJob.id,
            address: this.currentAddress.uuid,
            monthlySalary: this.monthlySalary ?? undefined,
            active: this.localJob.active,
            urgent: this.localJob.urgent,
          },
        });

        this.localJob.monthlySalary = this.monthlySalary;
        this.$emit('update:modelValue', this.localJob);
        this.$emit('job-updated', this.localJob);

        this.reset();

        this.toast.success((this.$t('components.edit_job_form.success') as string));
      } catch {
        this.toast.error((this.$t('components.edit_job_form.error') as string));
      }
    },

    reset(): void {
      this.suggestedJobs = [] as Array<JobSuggestion>;
    },
    onJobUpdate(newJob: AddressJob): void {
      this.localJob = cloneDeep(newJob);
    },
    setSalary(event: InputEvent): void {
      const input = event.target as HTMLInputElement;
      const { length } = input.value;

      input.value = input.value.replaceAll(/\s+/g, '');
      const n = Number.parseInt(input.value, 10);

      input.value = n ? new Intl.NumberFormat('fr-FR').format(n) : '';

      this.localJob.monthlySalary = input.value;

      // Set the cursor to the correct place (it's reset when we change input.value)
      let position = input.selectionStart ?? 0;
      position += input.value.length - length;
      input.selectionStart = position;
      input.selectionEnd = position;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../../scss/config/config';

.form-group {
  position: relative;
}

.dropdown-menu {
  background-color: $white;
  max-height: 440px;
  overflow-y: auto;
  padding: 10px;
  position: absolute;
  top: 38px;
  width: 100%;
  z-index: 10;
}

.dropdown-item {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: left;
  white-space: unset;
  width: 100%;

  &:not(:first-child) {
    border-color: $gray-300;
    border-width: 1px 0 0;
    box-shadow: inset 0 1px 2px rgb(0, 0, 0, .08);
  }
}
</style>
