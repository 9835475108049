import { ActionContext } from 'vuex';

import Address from '@/js/interfaces/address';
import { AddressJob, AddressJobPayloadBase } from '@/js/interfaces/address-job';
import AddressRegistration from '@/js/interfaces/address-registration';
import {
  HeadHunterAddressField,
  HeadHunterRecruiterField,
  HeadHunterRegisterPayload,
} from '@/js/interfaces/head-hunter-register-payload';
import Job from '@/js/interfaces/job';
import MapboxAddress from '@/js/interfaces/mapbox-address';
import Product from '@/js/interfaces/product';
import State from '@/js/store/state';

// eslint-disable-next-line max-len,vue/max-len
const addressHasContent = (address: AddressRegistration) => (Object.keys(address.content).length > 0 && address.content.constructor === Object);

export interface SaveAddressPayload {
  index: number;
  address: AddressRegistration;
}

interface RegisterState {
  currentStep: number;
  headHunter: HeadHunterRegisterPayload;
  informationValid: boolean;
  address: AddressRegistration | null;
  products: Array<Product>;
  addressQuery: string;
  addressJob: AddressJob;
}

const defaultHeadHunterRegisterPayload: HeadHunterRegisterPayload = {
  email: '',
  password: '',
  passwordRepeat: '',
  recruiter: {
    company: '',
    manager: '',
    phoneNumber: '',
    address: {} as MapboxAddress,
  } as HeadHunterRecruiterField,
  address: {
    name: undefined,
    logo: undefined,
    content: undefined,
    activitySector: undefined,
  } as HeadHunterAddressField,
  addressJob: {
    job: undefined,
    workingTime: undefined,
    remote: undefined,
    town: undefined,
    contracts: [],
    experiences: [],
    personalQualities: [],
    professionalKnowledge: [],
    professionalSoftSkills: [],
  } as AddressJobPayloadBase,
};

const emptyAddress: AddressRegistration = {
  logo: '',
  qrcodeTown: '',
  name: '',
  content: {} as MapboxAddress,
  activitySector: 0,
  subscription: null,
  products: [],
};

const emptyAddressJob: AddressJob = {
  id: 0,
  name: '',
  job: {} as Job,
  uuid: '',
  active: false,
  urgent: false,
  monthlySalary: '',
  contracts: [],
  workingTime: undefined,
  remote: undefined,
  town: undefined,
  experiences: undefined,
  personalQualities: undefined,
  professionalKnowledge: undefined,
  professionalSoftSkills: undefined,

};

const defaultState: RegisterState = {
  currentStep: 1,
  headHunter: defaultHeadHunterRegisterPayload,
  informationValid: false,
  address: emptyAddress,
  addressJob: emptyAddressJob,
  addressQuery: '',
  products: [],
};

type Context = ActionContext<RegisterState, State>;

const mutations = {
  setCurrentStep(state: RegisterState, step: number) {
    state.currentStep = step;
  },

  setProducts(state: RegisterState, products: Array<Product>) {
    state.products = products;
  },

  setHeadHunter(state: RegisterState, payload: HeadHunterRegisterPayload) {
    state.headHunter = payload;
  },

  setInformationValid(state: RegisterState, informationValid: boolean) {
    state.informationValid = informationValid;
  },

  setAddress(state: RegisterState, address: AddressRegistration): void {
    state.address = address;
  },

  setAddressQuery(state: RegisterState, addressQuery: string): void {
    state.addressQuery = addressQuery;
  },

  setAddressJob(state: RegisterState, addressJob: AddressJob): void {
    state.addressJob = addressJob;
  },
};

const getters = {
  subscriptions: (state: RegisterState) => state.products.filter((product) => product.prices.length > 1),
  goods: (state: RegisterState) => state.products.filter((product) => product.prices.length === 1),
};

const actions = {
  // Step
  setCurrentStep(context: Context, step: number) {
    context.commit('setCurrentStep', step);
  },

  setProducts(context: Context, products: Array<Product>) {
    context.commit('setProducts', products);
  },

  // Address
  addEmptyAddress(context: Context) {
    context.commit('setAddress', emptyAddress);
  },

  saveAddress(context: Context, payload: SaveAddressPayload) {
    context.commit('saveAddress', payload);
  },

  async reset(context: Context) {
    context.commit('setHeadHunter', defaultHeadHunterRegisterPayload);
    context.commit('setInformationValid', false);
    context.commit('setCurrentStep', 1);
    context.commit('setAddress', emptyAddress);
  },

  setHeadHunter(context: Context, payload: HeadHunterRegisterPayload) {
    context.commit('setHeadHunter', payload);
  },

  setInformationValid(context: Context, informationValid: boolean) {
    context.commit('setInformationValid', informationValid);
  },

  /**
   * Set the current address
   * @param context
   * @param address
   */
  setAddress(context: Context, address: Address): void {
    context.commit('setAddress', address);
  },

  /**
   * Set the current addressJob
   * @param context
   * @param addressJob
   */
  setAddressJob(context: Context, addressJob: AddressJob): void {
    context.commit('setAddressJob', addressJob);
  },

  /**
   * Set the addressQuery
   * @param context
   * @param addressQuery
   */
  setAddressQuery(context: Context, addressQuery: string): void {
    context.commit('setAddressQuery', addressQuery);
  },
};

const register = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default register;
