<template>
  <div v-if="action.name === actionTypesType.licences && action.active">
    <form
      class="licence-mobility">
      <div class="skill-category">
        <p class="form-title">
          {{ $t('views.add_new_address_job.address_job_step_3.licences.title') }}
        </p>
        <p>
          {{ $t('views.add_new_address_job.address_job_step_3.licences.subtitle') }}
        </p>
      </div>
      <div class="form-check-group">
        <div v-for="licence in licences" :key="licence.id" class="form-check custom-check">
          <input
            :id="`licence-${licence.id}`"
            v-model="selectedLicences"
            class="form-check-input new-licence-radio"
            type="checkbox"
            :value="licence">
          <label class="form-check-label custom-label" :for="`licence-${licence.id}`">
            <img :src="licence.icon" alt="licence-icon">
            <span class="licence-name">{{ licence.name }}</span>
          </label>
        </div>
      </div>
    </form>
    <form
      class="licence-mobility">
      <div class="skill-category">
        <p class="form-title">
          {{ $t('views.add_new_address_job.address_job_step_3.licences.title') }}
        </p>
        <p>
          {{ $t('views.add_new_address_job.address_job_step_3.licences.subtitle') }}
        </p>
      </div>
      <div class="form-check-group">
        <div v-for="locomotion in locomotions" :key="locomotion.id" class="form-check custom-check">
          <input
            :id="`locomotion-${locomotion.id}`"
            v-model="selectedLicences"
            class="form-check-input new-licence-radio"
            type="checkbox"
            :value="locomotion">
          <label class="form-check-label custom-label" :for="`locomotion-${locomotion.id}`">
            <img :src="locomotion.icon" alt="locomotion-icon">
            <span class="locomotion-name">{{ locomotion.name }}</span>
          </label>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">

import useVuelidate from '@vuelidate/core';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapMutations, mapState } from 'vuex';

import { ActionTypesAddAddressJob } from '@/js/enums/candidate-actions-type';

import ActionType from '../interfaces/action-type';
import { AddAddressJob } from '../interfaces/address-job';
import Licence from '../interfaces/licence';
import Locomotion from '../interfaces/locomotion';

export default defineComponent({
  name: 'CandidateProfileLicences',
  props: {
    licences: {
      type: Object as PropType<Licence[]>,
      required: true,
    },
    locomotions: {
      type: Object as PropType<Locomotion[]>,
      required: true,
    },
    action: {
      type: Object as PropType<ActionType>,
      required: true,
    },
  },
  setup() {
    const v = useVuelidate();
    return {
      v,
    };
  },
  data() {
    return {
      selectedLicences: [] as Licence[],
      selectedLocomotions: [] as Locomotion[],
      localAddressJob: (cloneDeep(this.addressJob) as unknown) as AddAddressJob,
      actionTypesType: ActionTypesAddAddressJob,
    };
  },
  validations() {
    return {};
  },
  computed: {
    ...mapState('job', [
      'addressJob',
    ]),
  },
  watch: {
    addressJob(newJob: AddAddressJob) {
      this.localAddressJob = newJob;
    },
    async selectedLicences(): Promise<void> {
      await this.localOnSelectLicences();
    },
    async selectedLocomotions(): Promise<void> {
      await this.localOnSelectLocomotions();
    },
  },
  async mounted(): Promise<void> {
    this.localAddressJob = cloneDeep(this.addressJob);
    this.setStoreAddressJobToLocal();
  },
  methods: {
    ...mapMutations('job', [
      'setAddressJob',
    ]),
    async localOnSelectLicences(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        licences: this.selectedLicences,
      };
      await this.setAddressJob(this.localAddressJob);
    },
    async localOnSelectLocomotions(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        locomotions: this.selectedLocomotions,
      };
      await this.setAddressJob(this.localAddressJob);
    },
    setStoreAddressJobToLocal(): void {
      this.selectedLicences = this.localAddressJob.licences;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.add-address-job .form-check-group {
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));

  .form-check-label {
    font-size: 16px;
    gap: 10px;
    min-height: 70px;
    text-align: left;
  }

  .form-check:last-of-type {
    grid-column: 1/-1;
  }
}
</style>
