<template>
  <div class="change-email-form">
    <p>
      <a href="" class="text-decoration-none" @click.prevent="modalOpened = true;" @keyup.e.enter.prevent="modalOpened = true;">
        {{ $t('components.change_email_form.cta') }}
      </a>
    </p>

    <v-modal :id="'changeEmailModal'" v-model="modalOpened">
      <form @submit.prevent="submitChangeEmail">
        <div class="form-group form-group-required" :class="{ 'is-invalid': oldEmail && v.oldEmail.$invalid }">
          <label class="form-label" for="old-email">{{ $t('components.change_email_form.old_email') }}</label>
          <input
            :id="'old-email'"
            v-model.trim="oldEmail"
            :name="'old-email'"
            class="form-control"
            :class="{ 'is-invalid': oldEmail && v.oldEmail.$invalid }">
          <div v-if="oldEmail && !v.oldEmail.required" class="invalid-feedback">{{ $t('form.validation.required') }}</div>
          <div v-if="oldEmail && !v.oldEmail.email.$valid" class="invalid-feedback">{{ $t('form.validation.email') }}</div>
        </div>

        <div class="form-group form-group-required" :class="{ 'is-invalid': newEmail && v.newEmail.$invalid }">
          <label class="form-label" for="new-email">{{ $t('components.change_email_form.new_email') }}</label>
          <input
            :id="'new-email'"
            v-model.trim="newEmail"
            :name="'new-email'"
            class="form-control"
            :class="{ 'is-invalid': newEmail && v.newEmail.$invalid }">
          <div v-if="newEmail && !v.newEmail.required" class="invalid-feedback">{{ $t('form.validation.required') }}</div>
          <div v-if="newEmail && !v.newEmail.email.$valid" class="invalid-feedback">{{ $t('form.validation.email') }}</div>
        </div>

        <div class="form-group form-group-required" :class="{ 'is-invalid': newEmailRepeat && v.newEmailRepeat.$invalid }">
          <label class="form-label" for="new-email-repeat">{{ $t('components.change_email_form.new_email_repeat') }}</label>
          <input
            :id="'new-email-repeat'"
            v-model.trim="newEmailRepeat"
            :name="'new-email-repeat'"
            class="form-control"
            :class="{ 'is-invalid': newEmailRepeat && v.newEmailRepeat.$invalid }">
          <div v-if="newEmailRepeat && !v.newEmailRepeat.required" class="invalid-feedback">{{ $t('form.validation.required') }}</div>
          <div v-if="newEmailRepeat && !v.newEmailRepeat.email.$valid" class="invalid-feedback">{{ $t('form.validation.email') }}</div>
        </div>

        <div class="d-grid mb-3">
          <button :disabled="v.$invalid" type="submit" class="btn btn-primary">{{ $t('actions.change_email') }}</button>
        </div>
      </form>
    </v-modal>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { email, required, sameAs } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions } from 'vuex';

import { ChangeEmailPayload } from '../interfaces/payload';

export default defineComponent({
  name: 'ChangeEmailForm',
  props: {},
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      modalOpened: false,
      oldEmail: '',
      newEmail: '',
      newEmailRepeat: '',
    };
  },
  validations() {
    return {
      oldEmail: {
        required,
        email,
      },
      newEmail: {
        required,
        email,
      },
      newEmailRepeat: {
        required,
        email,
        sameAsEmail: sameAs(this.newEmail),
      },
    };
  },
  methods: {
    ...mapActions('recruiter', [
      'changeEmail',
    ]),
    async submitChangeEmail() {
      try {
        const payload: ChangeEmailPayload = {
          oldEmail: this.oldEmail,
          newEmail: this.newEmail,
        };
        await this.changeEmail(payload);

        this.toast.success((this.$t('components.change_email_form.success') as string));
        this.modalOpened = false;
      } catch (error) {
        if (error.name === 'HTTPError') {
          const data = error.response.json();

          if ('oldEmail' in data) {
            this.toast.error(data.oldEmail.join(', '));
          }

          if ('newEmail' in data) {
            this.toast.error(data.newEmail.join(', '));
          }
        } else {
          this.toast.error((this.$t('components.change_email_form.error') as string));
        }
      }
    },
  },
});
</script>
