<template>
  <form @submit.prevent="submit">
    <div class="form-group" :class="{ 'is-invalid': siretError }">
      <label class="form-label" :for="'address_siret'">
        {{ $t('components.address_complete_siret_form.label.siret') }}
      </label>
      <input
        :id="'address_siret'"
        v-model="siret"
        class="form-control"
        type="text"
        name="address_siret"
        maxlength="14"
        :class="{ 'is-invalid': siretError }"
        placeholder="Insérez votre numéro Siret"
        @keyup="sanitizeSiret(siret)">

      <div v-if="siretError" class="invalid-feedback">
        {{ siretError }}
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-primary btn-rounded" type="submit" :disabled="disabled">Suivant</button>
    </div>
  </form>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import { useSiret } from '@/js/composition/siret';

import { CompleteStep } from '../store/modules/complete';

export default defineComponent({
  name: 'AddressCompleteSiretForm',
  setup() {
    const toast = useToast();
    const {
      siret, siretError, sanitizeSiret, submitSiret,
    } = useSiret();

    return {
      toast, siret, siretError, sanitizeSiret, submitSiret,
    };
  },
  computed: {
    ...mapState('complete', [
      'address',
    ]),
    disabled(): boolean {
      return this.siret.length !== 14;
    },
  },

  methods: {
    ...mapActions('complete', [
      'setStep',
      'setAddress',
      'setAddressQuery',
    ]),
    async submit(): Promise<void> {
      this.siretError = '';

      try {
        const response = await this.submitSiret();

        if (response) {
          // Update address with SIRENE API return
          const localAddress = cloneDeep(this.address);

          if (response.activitySector) {
            localAddress.activitySector = response.activitySector;
          }

          this.setAddress(localAddress);
          this.setAddressQuery(response.address);
        }
      } catch (error) {
        console.error(error);
      }

      this.setStep(CompleteStep.FORM);
    },
  },
});
</script>
