import { format } from 'date-fns';

import { CandidateShopItem, GroupedBySearchJob } from '../interfaces/candidate-shop-item';

export default function groupBySearchJobName(candidateList: CandidateShopItem[]): GroupedBySearchJob[] {
  return candidateList
    .sort((a, b) => b.matchingPercentage - a.matchingPercentage)
    .reduce((accumulator: GroupedBySearchJob[], candidate): GroupedBySearchJob[] => {
      const jobName = candidate.matchingSearchedJob.job.name;
      const purchasedDate = candidate.purchasedDate
        ? format(new Date(candidate.purchasedDate), 'dd/MM/yyyy')
        : undefined;

      const existingGroup = accumulator.find((group) => group.searchedJobName === jobName
        && group.purchasedDate === purchasedDate);

      if (existingGroup) {
        existingGroup.candidates.push(candidate);
      } else {
        accumulator.push({
          searchedJobName: jobName,
          purchasedDate,
          candidates: [candidate],
        });
      }

      return accumulator;
    }, []);
}
