import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toast-notification';

import { SiretResponse } from '../interfaces/siret';

// eslint-disable-next-line import/prefer-default-export
export function useSiret() {
  const siret = ref('');
  const toast = useToast();
  const siretError = ref('');
  const { t } = useI18n();
  const sanitizeSiret = (rawSiret: string): void => {
    siret.value = rawSiret;
    if (siret.value) {
      siret.value = siret.value.replace(/\D/, '');
      siretError.value = siret.value.length > 0 && siret.value.length !== 14 ? t('composition.siret.format_unvalidated') : '';
    }
  };

  const submitSiret = async (): Promise<SiretResponse | null> => {
    siretError.value = '';

    const payload = {
      siret: siret.value,
    };

    let response: SiretResponse | null = null;

    try {
      response = await window.ky.post('/api/siret/', { json: payload }).json<SiretResponse>();
    } catch (error) {
      console.error(error);
      siretError.value = t('composition.siret.not_found');
      toast.error((t('composition.siret.not_found') as string));
    }

    return response;
  };

  return {
    siret,
    siretError,
    sanitizeSiret,
    submitSiret,
  };
}
