export interface RoutesNames {
  home: string;
  register: string;
  registerStep1: string;
  registerStep2: string;
  registerStep3: string;
  registerStep4: string;
  registerSuccess: string;
  login: string;
  lostPassword: string;
  lostPasswordConfirm: string;
  dashboard: string;
  jobs: string;
  brand: string;
  brandPreview: string;
  publicResume: string;
  resumes: string;
  resumesShop: string;
  account: string;
  addresses: string;
  candidateProfile: string;
  candidateResumeDetail: string;
  addNewAddress: string;
  myQrCodes: string;
  pageNotFound: string;
  addressComplete: string;
  reset: string;
  addAddressJobStep1: string;
  addAddressJobStep2: string;
  addAddressJobStep3: string;
  editAddressJobStep1: string;
  editAddressJobStep2: string;
  editAddressJobStep3: string;
  shoppingBasket: string;
  shoppingBasketConfirmed: string;
}

const routesNames: Readonly<RoutesNames> = {
  home: 'Home',
  register: 'RegisterView',
  registerStep1: 'RegisterStep1',
  registerStep2: 'RegisterStep2',
  registerStep3: 'RegisterStep3',
  registerStep4: 'RegisterStep4',
  registerSuccess: 'RegisterSuccess',
  login: 'LoginView',
  lostPassword: 'LostPassword',
  lostPasswordConfirm: 'LostPasswordConfirm',
  dashboard: 'DashboardView',
  jobs: 'JobsView',
  brand: 'BrandView',
  brandPreview: 'BrandPreview',
  publicResume: 'PublicResume',
  resumes: 'ResumeView',
  resumesShop: 'ResumeShopView',
  candidateResumeDetail: 'CandidateResumeDetail',
  account: 'AccountView',
  addresses: 'AddressesView',
  candidateProfile: 'CandidateProfile',
  addNewAddress: 'AddNewAddress',
  myQrCodes: 'MyQrCodes',
  pageNotFound: 'PageNotFound',
  addressComplete: 'AddressComplete',
  reset: 'ResetView',
  addAddressJobStep1: 'AddAddressJobStep1',
  addAddressJobStep2: 'AddAddressJobStep2',
  addAddressJobStep3: 'AddAddressJobStep3',
  editAddressJobStep1: 'EditAddressJobStep1',
  editAddressJobStep2: 'EditAddressJobStep2',
  editAddressJobStep3: 'EditAddressJobStep3',
  shoppingBasket: 'ShoppingBasket',
  shoppingBasketConfirmed: 'ShoppingBasketConfirmed',

};

export default routesNames;
