<template>
  <div class="file-upload">
    <div>
      <div class="form-control">
        {{ $t('form.image.choose_file_text') }}
      </div>

      <div v-if="file" class="file-name steel-text">
        {{ file }}
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FileIndicator',
  props: {
    file: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
@use 'sass:math';
@import '../../scss/config/config';

.file-upload {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.file-indicator {
  align-items: center;
  background-color: $cyan-100;
  border: 1px solid $steel;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  height: 30px;
  padding: 6px;
}

.file-name {
  font-size: 14px;
  margin-bottom: auto;
  margin-left: 10px;
  margin-top: auto;
}

</style>
