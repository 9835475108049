<template>
  <div>
    <h5 class="text-center new-job-title">{{ $t('components.new_job_form.job_title') }}</h5>
    <div class="new-job-sections">
      <div class="step-section">
        <div class="d-none d-md-block">
          <button
            type="button"
            :class="['new-job-step', { 'active-step': currentStep === 1, 'passive-step': currentStep !== 1 }]">
            <span class="step-number">1</span> Intitulé du métier
          </button>
          <button
            type="button"
            :class="['new-job-step', { 'active-step': currentStep === 2, 'passive-step': currentStep !== 2 }]">
            <span class="step-number">2</span> Détails du métier
          </button>
        </div>
        <div class="d-md-none mobile-steps">
          {{ currentStep }}/2
        </div>
      </div>
      <form class="new-job-form" @submit.prevent="handleSubmit">
        <div v-if="currentStep === 1">
          <div class="form-group">
            <label for="job" class="form-label">{{ $t('components.new_job_form.job_label') }}</label>
            <input
              id="job"
              v-model="query"
              data-cy="new-job-form-input"
              type="text"
              class="form-control"
              :placeholder="$t('components.new_job_form.job_placeholder')"
              autocomplete="off"
              @input="lookupJobHandler(query)">
            <div v-if="suggestedJobs && suggestedJobs.length > 0" class="dropdown-menu show" data-cy="suggested-job-list">
              <button
                v-for="job in suggestedJobs"
                :key="job.id"
                type="button"
                class="dropdown-item"
                @click.prevent="localOnSelectJob(job)">
                <span v-html="job.Formatted.name" />
              </button>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" for="company">{{ $t('components.new_job_form.monthly_salary_label') }}</label>
            <input
              id="company"
              type="text"
              class="form-control"
              :placeholder="$t('components.new_job_form.monthly_salary_placeholder')"
              name="company"
              :disabled="!selectedJob"
              @input.prevent="setSalary($event as InputEvent)">
          </div>

          <div class="form-check form-switch job-card-switch">
            <input
              id="active"
              v-model="active"
              class="form-check-input btn-checked-red-to-green"
              type="checkbox"
              role="switch"
              :aria-checked="active">
            <label class="form-check-label" for="active">
              {{ $t('components.job_card.active') }}
            </label>
          </div>

          <div class="form-check form-switch job-card-switch mb-3">
            <input
              id="urgent"
              v-model="urgent"
              class="form-check-input btn-checked-red-to-green"
              type="checkbox"
              role="switch"
              :aria-checked="urgent">
            <label class="form-check-label" for="urgent">
              {{ $t('components.job_card.urgent') }}
            </label>
          </div>
        </div>

        <div v-if="currentStep === 2">
          <div class="form-group">
            <div class="form-label">Statut du contrat souhaité*</div>
            <div class="form-check-group">
              <div v-for="contract in contracts" :key="contract.id" class="form-check custom-check">
                <input
                  :id="`contract-${contract.id}`"
                  v-model.number="selectedContract"
                  class="form-check-input new-job-radio"
                  type="radio"
                  :value="contract">
                <label class="form-check-label custom-label" :for="`contract-${contract.id}`">{{ contract.name }}</label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form-label">Quel sera le temps de travail ?*</div>
            <div class="form-check-group">
              <div v-for="workingTime in workingTimes" :key="workingTime.id" class="form-check custom-check">
                <input
                  :id="`working-time-${workingTime.id}`"
                  v-model.number="selectedWorkingTime"
                  class="form-check-input new-job-radio"
                  type="radio"
                  :value="workingTime">
                <label class="form-check-label custom-label" :for="`working-time-${workingTime.id}`">{{ workingTime.name }}</label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form-label">Fréquence de télétravail?*</div>
            <div class="form-check-group">
              <div v-for="remote in remotes" :key="remote.id" class="form-check custom-check">
                <input
                  :id="`remote-${remote.id}`"
                  v-model.number="selectedRemote"
                  class="form-check-input new-job-radio"
                  type="radio"
                  :value="remote">
                <label class="form-check-label custom-label" :for="`remote-${remote.id}`">{{ remote.name }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex gap-3 new-job-buttons">
          <button
            v-if="currentStep === 1"
            type="button"
            class="btn btn-outline-primary btn-rounded"
            @click.prevent="$emit('cancel')">
            {{ $t('actions.cancel') }}
          </button>

          <button
            v-if="currentStep === 2"
            type="button"
            class="btn btn-outline-primary btn-rounded"
            @click.prevent="prevStep">
            {{ $t('actions.back') }}
          </button>
          <button
            v-if="currentStep < 2"
            class="btn btn-primary btn-rounded"
            type="button"
            :disabled="!selectedJob"
            @click.prevent="nextStep">
            {{ $t('actions.next') }}
          </button>
          <button
            v-if="currentStep === 2"
            class="btn btn-primary btn-rounded"
            type="submit"
            :disabled="isSubmitDisabled">
            {{ $t('actions.save') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapState } from 'vuex';

import { useJobOptions } from '../composition/job-options';
import { useJobSearch } from '../composition/job-search';
import Contract from '../interfaces/contract';
import Job from '../interfaces/job';
import JobSuggestion from '../interfaces/job-suggestion';
import Remote from '../interfaces/remote';
import WorkingTime from '../interfaces/working-time';

export default defineComponent({
  name: 'NewJobForm',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue', 'cancel', 'job-added'],
  setup() {
    const {
      fetchOptions,
      contracts,
      workingTimes,
      remotes,
    } = useJobOptions();
    const toast = useToast();
    const {
      suggestedJobs,
      lookupJobHandler,
      resetSuggestedJobs,
    } = useJobSearch();
    return {
      toast,
      fetchOptions,
      contracts,
      workingTimes,
      remotes,
      suggestedJobs,
      lookupJobHandler,
      resetSuggestedJobs,
    };
  },
  data() {
    return {
      query: '',
      selectedJob: null as (Job|null),
      monthlySalary: null as (string|null),
      active: true,
      urgent: true,
      currentStep: 1,
      selectedContract: null as (Contract|null),
      selectedWorkingTime: null as (WorkingTime|null),
      selectedRemote: null as (Remote|null),
    };
  },
  computed: {
    ...mapState('recruiter', [
      'currentAddress',
    ]),
    monthlySalaryAsNumber(): number {
      let s = this.monthlySalary ?? '';
      s = s.replaceAll(/\s+/g, '');
      return Number.parseInt(s ?? '0', 10);
    },
    isSubmitDisabled(): boolean {
      return (
        this.selectedJob === null
        || this.selectedContract === null
        || this.selectedWorkingTime === null
        || this.selectedRemote === null
      );
    },
  },
  watch: {
    modalOpened(newValue: boolean): void {
      if (newValue) {
        this.reset();
        this.resetStep();
      }
    },
  },
  created() {
    this.fetchOptions();
  },
  methods: {
    async lookupJob(): Promise<void> {
      if (this.query.length < 3) {
        if (this.query.length === 0) this.reset();
        return;
      }

      const data = await window.ky.get(`/api/jobs/search/?term=${this.query}`).json();
      this.suggestedJobs = data.hits;
    },

    onSelectJob(value: Job): void {
      this.selectedJob = value;
      this.query = this.selectedJob.name;
      this.suggestedJobs = [];
    },

    async addJob(): Promise<void> {
      if (
        this.selectedJob === null
      || this.selectedContract === null
      || this.selectedWorkingTime === null
      || this.selectedRemote === null
      ) {
        return;
      }

      try {
        await window.ky.post('/api/addresses-jobs/', {
          json: {
            job: this.selectedJob.id,
            address: this.currentAddress.uuid,
            monthlySalary: this.monthlySalaryAsNumber,
            active: this.active,
            urgent: this.urgent,
            contract: this.selectedContract.id,
            workingTime: this.selectedWorkingTime.id,
            remote: this.selectedRemote.id,
          },
        }).json<void>();

        this.reset();
        this.$emit('update:modelValue', false);
        this.$emit('job-added');
        this.toast.success((this.$t('components.new_job_form.success') as string));
      } catch {
        this.toast.error((this.$t('components.new_job_form.error') as string));
      }
    },

    nextStep(): void {
      if (this.currentStep < 2) {
        this.currentStep += 1;
      }
    },

    prevStep(): void {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    handleSubmit(): void {
      if (this.currentStep === 2) {
        this.addJob();
      } else {
        this.nextStep();
      }
    },

    reset(): void {
      this.selectedJob = null;
      this.query = '';
      this.suggestedJobs = [] as Array<JobSuggestion>;
      this.monthlySalary = null;
      this.active = true;
      this.urgent = false;
      this.selectedContract = null;
      this.selectedWorkingTime = null;
      this.selectedRemote = null;
    },

    resetStep(): void {
      this.currentStep = 1;
    },

    setSalary(event: InputEvent): void {
      const input = event.target as HTMLInputElement;
      const { length } = input.value;

      input.value = input.value.replaceAll(/\s+/g, '');
      const n = Number.parseInt(input.value, 10);

      input.value = n ? new Intl.NumberFormat('fr-FR').format(n) : '';

      this.monthlySalary = input.value;

      let position = input.selectionStart ?? 0;
      position += input.value.length - length;
      input.selectionStart = position;
      input.selectionEnd = position;
    },
    localOnSelectJob(value: Job) {
      this.selectedJob = value;
      this.query = value.name;
      this.resetSuggestedJobs();
    },
  },
});

</script>

<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.form-group {
  position: relative;
}

.dropdown-menu {
  background-color: $white;
  padding: 10px;
  position: absolute;
  top: 78px;
  width: 100%;
  z-index: 10;
}

.dropdown-item {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: left;
  white-space: normal;
  width: 100%;

  &:not(:first-child) {
    border-color: $gray-300;
    border-width: 1px 0 0;
    box-shadow: inset 0 1px 2px rgb(0, 0, 0, .08);
  }
}

.new-job-sections {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include mq($from: tablet) {
    flex-direction: row;
  }
}

.step-number {
  border-radius: 50%;
  display: block;
  font-size: 20px;
  height: 30px;
  text-align: center;
  width: 30px;
}

.active-step {
  font-size: 20px;
  font-weight: bold;

  .step-number {
    background: rgb(53, 43, 174);
    color: $white;
  }
}

.passive-step {
  .step-number {
    background: $white;
    border: 1px solid rgb(53, 43, 174);
    color: rgb(53, 43, 174);
  }
}

.step-section {
  display: flex;
  gap: 20px;
  justify-content: end;

  @include mq($from: tablet) {
    border-right: 1px solid rgb(215, 213, 211);
    flex-direction: column;
    justify-content: unset;
    padding-right: 64px;
    width: 61%;
  }
}

.new-job-form {
  @include mq($from: tablet) {
    padding-left: 64px;
    width: 100%;
  }
}

.new-job-step {
  align-items: center;
  background: none;
  border: 0;
  cursor: default;
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  text-align: left;

  &:hover {
    text-decoration: none;
  }
}

.form-check-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.custom-check {
  display: inline-block;
}

.custom-check .new-job-radio {
  display: none;
}

.custom-check label {
  border: 2px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
}

.custom-check .new-job-radio:checked + label {
  background-color: $black;
  color: $white;
}

.mobile-steps {
  font-size: 20px;
  text-align: right;
}

.btn-primary:disabled {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

.new-job-title {
  font-size: 22px;
  margin-bottom: 24px;

  @include mq($from: tablet) {
    font-size: 30px;
    margin-bottom: 64px;
  }
}

.new-job-buttons {
  justify-content: center;
  margin-top: 24px;

  @include mq($from: tablet) {
    justify-content: end;
    margin-top: 64px;
  }
}
</style>
