<template>
  <button
    type="button"
    :class="{ 'selected-item': selected, 'is-valid-field': validField }"

    class="btn btn-white"
  >
    <div class="list-item-content">
      <slot name="content" />
    </div>

    <div class="item-actions">
      <slot name="actions" />
    </div>
  </button>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppListItem',
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    validField: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style lang="scss" scoped>
@import '../../scss/config/config';
@import 'sass-mq/mq';

.btn {
  align-items: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto;
  padding: 20px;
  text-align: left;
  width: 100%;
}

.btn-white {
  &:focus {
    border-color: $primary;
    box-shadow: unset;
    color: $primary;
  }
}

.selected-item {
  border: 1px solid #352bae;
}

.is-valid-field {
  background-color: #352bae;
  color: #fff;
}
</style>
