<template>
  <div class="bread-crumb">
    <button v-if="currentStep > 1" class="step-back" type="button">
      <v-sprite class="step-back-icon" color="var(--bs-primary)" icon="arrow-back" @click.prevent="moveStepBack()" />
    </button>
    <div class="breadcrumb-indicators">
      <div class="progress">
        <div class="progress-bar" role="progressbar" :aria-valuenow="(currentStep / 4) * 100" aria-valuemin="0" aria-valuemax="100" />
      </div>
      <span class="current-step">{{ $t('components.register_breadcrumb.step') }} {{ currentStep }}/4</span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import routesNames, { RoutesNames } from '../router/route-names';

export default defineComponent({
  name: 'AppBreadCrumb',
  computed: {
    routeNames(): RoutesNames {
      return routesNames;
    },
    ...mapState('register', [
      'currentStep',
    ]),
  },
  methods: {
    moveStepBack(): void {
      const currentStep = this.currentStep > 1 ? this.currentStep - 1 : 1;
      this.setCurrentStep(currentStep);
      this.$router.push({ name: `RegisterStep${currentStep}` });
    },
    ...mapActions('register', [
      'setCurrentStep',
    ]),
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.bread-crumb {
  margin-block: 32px;
  width: 100%;

  @include mq($from: tablet) {
    margin-block: 60px;
  }

  .progress {
    flex-grow: 1;
  }

  .current-step {
    color: $steel;
    font-size: 14px;
    font-weight: 700;
  }
}

.step-back {
  background: transparent;
  border: 0;
}

.step-back-icon {
  height: 24px;
  margin-bottom: 16px;
  width: 28px;
}

.breadcrumb-indicators {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
}
</style>
