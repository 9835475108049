<template>
  <form
    v-if="action.name === actionTypesType.personalQuality && action.active"
    class="personal_quality" @submit.prevent="saveTheStep(); $emit('selectAction')">
    <div class="form-header">
      <p class="title">
        {{ $t('views.register_step_3.personal_quality.title') }}
      </p>
      <p class="subtitle">
        {{ $t('views.register_step_3.personal_quality.subtitle') }}
      </p>
    </div>

    <div v-if="personalQualities" class="form-check-group personal-qualities">
      <div v-for="quality in personalQualities" :key="quality.id" class="form-check custom-check">
        <input
          :id="`quality-${quality.id}`"
          v-model="selectedPersonalQualities"
          class="form-check-input new-quality-radio"
          type="checkbox"
          :value="quality">
        <label class="form-check-label custom-label" :for="`quality-${quality.id}`">
          {{ quality.name }}
        </label>
      </div>
    </div>
    <small
      v-if="v.selectedPersonalQualities.$invalid"
      class="invalid"
    >{{ $t('form.validation.required') }}
    </small>
    <br>
    <small
      v-if="v.selectedPersonalQualities.maxLength.$invalid"
      class="invalid"
    >{{ $t('views.register_step_3.personal_quality.invalid_selections') }} {{ selectedPersonalQualities.length }}
    </small>
    <div class="register-submit">
      <button
        type="submit" class="btn btn-primary btn-rounded"
        :disabled="v.selectedPersonalQualities.$invalid"
      >
        {{ $t('actions.save') }}
      </button>
    </div>
  </form>
</template>
<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required } from '@vuelidate/validators';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'vuex';

import { ActionTypesType } from '@/js/enums/candidate-actions-type';

import ActionType from '../interfaces/action-type';
import { AddressJob } from '../interfaces/address-job';
import { Skill } from '../interfaces/skill';

export default defineComponent({
  name: 'RegisterPersonalQuality',
  props: {
    personalQualities: {
      type: Object as PropType<Skill[]>,
      required: true,
    },
    action: {
      type: Object as PropType<ActionType>,
      required: true,
    },
  },
  emits: ['selectAction'],
  setup() {
    const v = useVuelidate();
    return {
      v,
    };
  },
  data() {
    return {
      selectedPersonalQualities: [] as Skill[],
      localAddressJob: (cloneDeep(this.addressJob) as unknown) as AddressJob,
      actionTypesType: ActionTypesType,
    };
  },
  validations() {
    return {
      selectedPersonalQualities: { required, minLength: minLength(1), maxLength: maxLength(5) },
    };
  },
  computed: {
    ...mapState('register', [
      'currentStep',
      'addressJob',
    ]),
  },
  watch: {
    addressJob(newJob: AddressJob) {
      this.localAddressJob = newJob;
    },
  },
  async mounted(): Promise<void> {
    this.localAddressJob = this.addressJob;
  },
  methods: {
    ...mapActions('register', [
      'setAddressJob',
    ]),
    async localOnSelectPersonalQualities(): Promise<void> {
      this.localAddressJob = {
        ...this.localAddressJob,
        personalQualities: this.selectedPersonalQualities,
      };
      await this.setAddressJob(this.localAddressJob);
    },
    async saveTheStep(): Promise<void> {
      await this.localOnSelectPersonalQualities();
    },
  },
});
</script>
<style lang="scss" scoped>
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.step3-forms {
  .personal-qualities {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include mq($from: mobile) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  .form-check-label {
    font-size: 16px;
    height: 50px;
  }
}
</style>
