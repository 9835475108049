<template>
  <header class="register-header">
    <AppLogo :color="logoType.pink" />
    <div class="register-header-subtitle">
      <slot />
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AppLogo from '@/js/components/AppLogo.vue';

import LogoType from '../enums/logoType.d';

export default defineComponent({
  name: 'RegisterHeader',
  components: { AppLogo },
  data() {
    return {
      logoType: LogoType,
    };
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';

</style>
