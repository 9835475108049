<template>
  <router-link
    :to="{ name: routeNames.candidateResumeDetail, params: { uuid: candidate.uuid } }"
    class="candidate-card"
    :class="{ 'is-rare': candidate.tag }"
  >
    <img
      v-if="candidate?.candidate?.avatar"
      width="204"
      height="242"
      :src="candidate?.candidate?.avatar"
      alt=""
      class="candidate-image"
    >
    <img
      v-else
      width="204"
      height="242"
      src="@/images/anonymous-profile.jpg"
      alt=""
      class="candidate-image"
    >

    <div class="candidate-actions">
      <button v-if="favorite" class="btn btn-sm favorite" :class="{ 'is-favorite': candidate.favorite }" type="button" @click.prevent="triggerFavoriteCandidate()">
        <v-sprite color="currentColor" icon="heart" />
      </button>
      <button v-if="basket" class="btn btn-sm addtocart" type="button" @click.prevent="setUuidItemInShoppingList(candidate)">
        <v-sprite v-if="localIsCandidateInBasket(candidate)" color="currentColor" icon="check" />
        <v-sprite v-else color="currentColor" icon="addtocart" />
      </button>
    </div>
    <div class="candidate-infos">
      <p class="name">
        <span>{{ candidate.firstName }}</span>
        <span class="match-percentage">( {{ candidate.matchingPercentage.toFixed(0) }}% )</span>
      </p>
      <p v-if="candidate.tag" class="tag">{{ candidate.tag.name }}</p>
      <p class="searched-job">
        {{ candidate?.matchingSearchedJob?.job.name ? candidate?.matchingSearchedJob?.job.name : '' }}
      </p>
      <p class="experience">
        Exp : {{ candidate?.matchingSearchedJob.experience?.name ? candidate?.matchingSearchedJob.experience.name : '' }}
      </p>
    </div>
  </router-link>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

import { CandidateShopItem } from '../interfaces/candidate-shop-item';
import routesNames, { RoutesNames } from '../router/route-names';

interface FavoriteCandidateResponse {
  recruiter: number;
  candidate: number;
}

export default defineComponent({
  name: 'CandidateCard',
  props: {
    candidate: {
      type: Object as PropType<CandidateShopItem>,
      required: true,
    },
    basket: {
      type: Boolean,
      default: true,
    },
    favorite: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['refresh-resumes'],
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    routeNames(): RoutesNames {
      return routesNames;
    },
    ...mapState('resumeShop', [
      'shoppingListUuids',
    ]),
  },
  methods: {
    ...mapActions('resumeShop', [
      'setUuidItemInShoppingList',
    ]),
    async triggerFavoriteCandidate(): Promise<void> {
      const payload = { uuid: this.candidate.uuid };
      try {
        const result = await window.ky.post('/api/favorite-candidate/', { json: payload }).json<FavoriteCandidateResponse>();
        if (result) {
          this.$emit('refresh-resumes');
        }
      } catch {
        this.toast.error((this.$t('views.resume_shop.candidate_resume_detail.get_candidate_error') as string));
      }
    },
    localIsCandidateInBasket(candidate: CandidateShopItem): boolean {
      if (!this.shoppingListUuids) return false;
      const candidateIndex = this.shoppingListUuids.indexOf(candidate.uuid);
      return candidateIndex !== -1;
    },

  },
});
</script>
