<template>
  <div class="cart-details">
    <div class="candidates-group" v-for="(group, index) in candidatesBySearchedJob" :key="index">
      <h3 class="h5">{{ group.searchedJobName }} ({{ group.candidates.length }})</h3>
      <div class="candidates-grid">
        <div
          v-for="(candidate, i) in group.candidates"
          :key="i"
          class="candidate-card-cart"
        >
          <v-candidate-card
            :basket="false"
            :favorite="actions"
            :candidate="candidate"
            @refresh-resumes="$emit('refresh-resumes');"
          />
          <button
            v-if="actions"
            type="button"
            class="delete-candidate"
            @click="$emit('delete-candidate', candidate)">
            Supprimer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { GroupedBySearchJob } from '../interfaces/candidate-shop-item';

export default defineComponent({
  name: 'ShoppingListItemsResume',
  props: {
    candidatesBySearchedJob: {
      type: Object as PropType<GroupedBySearchJob[]>,
      required: true,
    },
    actions: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['delete-candidate', 'refresh-resumes'],
});
</script>
