import { throttle } from 'lodash';
import { ref } from 'vue';

import JobSuggestion from '../interfaces/job-suggestion';

// eslint-disable-next-line import/prefer-default-export
export function useJobSearch() {
  const query = ref('');
  const suggestedJobs = ref<JobSuggestion[]>([]);

  const reset = () => {
    query.value = '';
    suggestedJobs.value = [];
  };

  const lookupJob = async (): Promise<void> => {
    if (query.value.length < 3) {
      if (query.value.length === 0) reset();
      return;
    }

    const data = await window.ky.get(`/api/jobs/search/?term=${query.value}`).json();
    suggestedJobs.value = data.hits;
  };

  const resetSuggestedJobs = () => {
    suggestedJobs.value = [];
  };

  const lookupJobHandler = async (value: string): Promise<void> => {
    query.value = value;
    const executeThrottle = throttle(lookupJob, 200);
    await executeThrottle();
  };

  return {
    query,
    suggestedJobs,
    lookupJobHandler,
    resetSuggestedJobs,
  };
}
