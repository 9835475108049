import Cookies from 'js-cookie';
import { ActionContext } from 'vuex';

import { CookiesListNames } from '@/js/enums/cookies-list-names';
import { StripesResumeProductsNames } from '@/js/enums/stripe-products-ids';
import { CandidateShopItem } from '@/js/interfaces/candidate-shop-item';
import Product from '@/js/interfaces/product';

import State from '../state';

interface ResumeShopState {
  shoppingList: CandidateShopItem[];
  shoppingListUuids: string[];
  resumesFilterCount: number;
  products: Product[];
}

const defaultState: ResumeShopState = {
  shoppingList: [],
  resumesFilterCount: 0,
  products: [],
  shoppingListUuids: [],
};

// eslint-disable-next-line unicorn/prefer-set-has
const stripesResumeProductsIds: string[] = [
  StripesResumeProductsNames.regularCandidate,
  StripesResumeProductsNames.rareGemCandidate,
];
type Context = ActionContext<ResumeShopState, State>;
const mutations = {
  setShoppingListUuids(state: ResumeShopState, shoppingListUuids: string[]) {
    state.shoppingListUuids = shoppingListUuids;
  },
  setUuidItemInShoppingList(state: ResumeShopState, shoppingItem: CandidateShopItem) {
    const candidateIndex = state.shoppingListUuids.indexOf(shoppingItem.uuid);
    // eslint-disable-next-line unicorn/prefer-ternary
    if (candidateIndex === -1) {
      state.shoppingListUuids = [...state.shoppingListUuids, shoppingItem.uuid];
    } else {
      state.shoppingListUuids = state.shoppingListUuids.filter(
        (uuid) => uuid !== shoppingItem.uuid,
      );
    }
  },
  deleteUuidItemFromShoppingList(state: ResumeShopState, candidates: CandidateShopItem[]) {
    const candidatesUuid = new Set(candidates.map((c) => c.uuid));
    state.shoppingListUuids = state.shoppingListUuids.filter(
      (uuid) => !candidatesUuid.has(uuid),
    );
  },
  setProducts(state: ResumeShopState, products: Array<Product>) {
    state.products = products;
  },
  setResumesFilterCount(state: ResumeShopState, count: number) {
    state.resumesFilterCount = count;
  },
};
const getters = {
  basketItemsCount: (state: ResumeShopState): number => state.shoppingListUuids.length,
  isBasketEmpty: (state: ResumeShopState): boolean => state.shoppingListUuids.length > 0,
  candidateGoods: (state: ResumeShopState) => state.products.filter((product) => stripesResumeProductsIds.includes(product.name)),
};
const actions = {
  setUuidItemInShoppingList(context: Context, shoppingItem: CandidateShopItem) {
    context.commit('setUuidItemInShoppingList', shoppingItem);
    Cookies.set(
      CookiesListNames.candidatesShoppingList,
      JSON.stringify(context.state.shoppingListUuids),
      { secure: true, sameSite: 'strict', expires: 14 },
    );
  },

  setShoppingListUuids(context: Context, shoppingListUuids: string[]) {
    context.commit('setShoppingListUuids', shoppingListUuids);
  },
  deleteUuidItemFromShoppingList(context: Context, candidates: CandidateShopItem[]) {
    context.commit('deleteUuidItemFromShoppingList', candidates);
    Cookies.set(
      CookiesListNames.candidatesShoppingList,
      JSON.stringify(context.state.shoppingListUuids),
      { secure: true, sameSite: 'strict', expires: 14 },
    );
  },
};
const resumeShop = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default resumeShop;
